import React, { useEffect, Profiler, useState } from "react";
// import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";
import "./Component-css/Downloadbutton.css?v=0.1";
// let deferredPrompt;
const Downloadbutton = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  return (
    <div>
      
      <div className="text-center" style={{ zoom: "1.2", padding: "10px 0px" }}>
        <button
          onClick={onClick}
          className="btn btn-primary btn-sm bg-transparent"
          style={{ fontWeight: 500, backgroundColor: "#3A427D" }}
        >
          <i className="fa fa-download text-white"/>
        </button>
      </div>
    </div>
  );
};

export default Downloadbutton;
